import { WorkwearExpressCustomisationPlatformFrontendDataItemCustomisationInterface } from 'swagger/cypress/Definitions';
import {
    WorkwearExpressCustomisationPlatformFrontendDataAppliedItemCustomisationInterface,
    WorkwearExpressCustomisationPlatformFrontendDataFullCustomisationInterface,
    WorkwearExpressCustomisationPlatformFrontendDataRemoveCustomisationInterface,
} from 'swagger/ts/Definitions';
import { sortLocations } from 'src/components/CustomisationPage/hooks/usePositions';
import { CartItem } from '@wearejh/m2-pwa-cart-gql/lib/index';

import { FromCustomisation } from './customisation.actions';

export const UPLOAD_PATH = '/customisations/image/upload';
export enum Step {
    Artwork = 'Artwork',
    ApplicationMethod = 'ApplicationMethod',
    ApplicationType = 'ApplicationType',
    Config = 'Config',
    Position = 'Position',
}

export enum ApplicationMethod {
    Both = 1,
    Print = 2,
    Embroidery = 3,
    None = 0,
}

export enum ApplicationMethodAPI {
    Print = 1,
    Embroidery = 2,
    None = 0,
}

export enum ApplicationType {
    Logo = 'Logo',
    Text = 'Text',
    None = 'None',
}

export interface ProductLocation {
    image_url?: string;
    name: string;
    method: ApplicationMethod;
    type?: ApplicationMethod;
}

export type AvailableLocation = {
    name: string;
    method: ApplicationMethod;
};

export function applicationMethodFromInt(type: number): ApplicationMethod {
    switch (type) {
        case 3:
            return ApplicationMethod.Embroidery;
        case 2:
            return ApplicationMethod.Print;
        default:
            return ApplicationMethod.Both;
    }
}

/**
 * To get a ApplicationMethod, all we need is a collection of objects
 * that all have { method: ApplicationMethod }
 * @param locations
 */
export function methodFromLocations<T extends { method: ApplicationMethod; name: string }>(
    locations: T[],
    selectedLocations: string | string[],
): ApplicationMethod {
    const filteredLocations = sortLocations(locations, selectedLocations);
    const types = new Set<ApplicationMethod>(filteredLocations.map((loc) => loc.method));
    if (types.has(ApplicationMethod.Print) && types.has(ApplicationMethod.Both)) return ApplicationMethod.Print;
    if (types.has(ApplicationMethod.Both) && types.has(ApplicationMethod.Embroidery))
        return ApplicationMethod.Embroidery;
    if (types.has(ApplicationMethod.Print) && types.has(ApplicationMethod.Embroidery)) return ApplicationMethod.Both;
    if (types.has(ApplicationMethod.Both)) return ApplicationMethod.Both;
    if (types.has(ApplicationMethod.Print)) return ApplicationMethod.Print;
    if (types.has(ApplicationMethod.Embroidery)) return ApplicationMethod.Embroidery;
    return ApplicationMethod.Both;
}

export const Fields = {
    artwork: 'artwork',
    image: 'image',
    textLineOne: 'textLineOne',
    textLineTwo: 'textLineTwo',
    textLineThree: 'textLineThree',
    font: 'font',
    color: 'color',
    isContactRequested: 'isContactRequested',
    specialInstructions: 'specialInstructions',
    customisationName: 'customisationName',
    applicationMethod: 'applicationMethod',
    applicationType: 'applicationType',
};

export function createStateFromCustomisation(input: CustomisationFull): FromCustomisation {
    return {
        id: input.id,
        artwork_id: input.artwork_id,
        textLineOne: input.text_line_one,
        textLineTwo: input.text_line_two,
        textLineThree: input.text_line_three,
        color: input.color,
        font: input.font,
        image: input.image,
        customisationName: input.customisation_name,
        specialInstructions: input.special_instructions,
        isContactRequested: input.is_contact_requested,
        application_method: applicationMethodFromInt(input.type),
        application_type: input.image ? ApplicationType.Logo : ApplicationType.Text,
        is_free: input.is_free,
        is_artwork_missing: input.is_artwork_missing,
    };
}

/**
 * convert form values to the correct output
 * @param customisation
 */
export function formStateToCustomisationPayload(
    customisation: Record<string, any>,
    artworkIsFree: boolean,
    artworkExistInDB: number,
) {
    return {
        customisationName: customisation.customisationName || '',
        image: customisation.image || '',
        type: customisation.applicationMethod,
        isContactRequested: customisation.isContactRequested === 'contact' || artworkExistInDB === 1,
        specialInstructions: customisation.specialInstructions || '',
        textLineOne: customisation.textLineOne || '',
        textLineTwo: customisation.textLineTwo || '',
        textLineThree: customisation.textLineThree || '',
        color: customisation.color || '',
        font: customisation.font || '',
        id: customisation.id || undefined,
        artworkId: customisation.artwork_id || undefined,
        isFree: artworkIsFree ?? false,
        is_artwork_missing: artworkExistInDB || false,
    };
}

/**
 * This is needed since the API does not seem to accept the keys in regular snake_case
 * @param customisation
 */

export type CustomisationFull = WorkwearExpressCustomisationPlatformFrontendDataFullCustomisationInterface;
export type BasketItemCustomisation = WorkwearExpressCustomisationPlatformFrontendDataAppliedItemCustomisationInterface;
export type BasketCustomisations = WorkwearExpressCustomisationPlatformFrontendDataItemCustomisationInterface;
export type BasketItemRemoveCustomisation = WorkwearExpressCustomisationPlatformFrontendDataRemoveCustomisationInterface;

export type CustomisationWithIdAndLocation = {
    id: number;
    location_names: string[];
    customisation: CustomisationFull;
};

export type Targets = {
    validTargets: CartItem[];
    invalidTargets: CartItem[];
};
