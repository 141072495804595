import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';

export function usePositions(locations, formState) {
    const locationNames = Object.keys(formState.values).filter((key) => formState.values[key] === true);
    const selectedWithMethod = sortLocations(locations, locationNames);
    return convertLocationsToMethod(selectedWithMethod);
}

export function sortLocations(locations, locationNames) {
    return locations.filter((loc) => {
        if (locationNames.indexOf(loc.name) >= 0) {
            return loc;
        }
    });
}
export function convertLocationsToMethod(sortedLocations) {
    if (sortedLocations.length === 0) {
        return ApplicationMethod.Both;
    }
    if (sortedLocations.some((x) => x.method === ApplicationMethod.Print)) {
        return ApplicationMethod.Print;
    }
    if (sortedLocations.some((x) => x.method === ApplicationMethod.Embroidery)) {
        return ApplicationMethod.Embroidery;
    }
    return ApplicationMethod.Both;
}
